import * as tslib_1 from "tslib";
import { Player } from "./player/player.service";
var MediaItem = /** @class */ (function () {
    /**
     * MediaItem Service Constructor.
     */
    function MediaItem(player) {
        this.player = player;
        /**
         * Whether service is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Data that is being paginated for media item.
         */
        this.paginatedData = [];
        /**
         * Active service subscriptions.
         */
        this.subscriptions = [];
        /**
         * Whether items are being loaded currently.
         */
        this.loading = false;
    }
    /**
     * Play all media item tracks.
     */
    MediaItem.prototype.play = function (tracks) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.player.queue.itemIsQueued(this.item.id)) return [3 /*break*/, 2];
                        this.player.handleContinuousPlayback = false;
                        return [4 /*yield*/, this.player.overrideQueue({ tracks: tracks || this.getTracks(), queuedItemId: this.item.id })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the playlist.
     */
    MediaItem.prototype.pause = function () {
        this.player.pause();
    };
    /**
     * Check if media item is playing currently.
     */
    MediaItem.prototype.playing = function (item) {
        if (!item)
            item = this.item;
        return this.player.state.playing && this.player.queue.getQueuedItem() === item['id'];
    };
    /**
     * Get the media item.
     */
    MediaItem.prototype.get = function () {
        return this.item;
    };
    /**
     * Set property on media item object.
     */
    MediaItem.prototype.set = function (prop, value) {
        this.item[prop] = value;
    };
    /**
     * Bootstrap the service.
     */
    MediaItem.prototype.init = function (id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (this.bootstrapped && this.item && this.item.id === id)
            return;
        this.destroy();
        return this.loadItem(id, params).then(function (response) {
            _this.bindToPlaybackEndEvent();
            _this.bootstrapped = true;
            return response;
        }, function () { });
    };
    /**
     * Set item and pagination data on service.
     */
    MediaItem.prototype.setItem = function (item, pagination) {
        this.item = item;
        this.pagination = pagination;
        this.paginatedData = pagination.data;
    };
    /**
     * Destroy playlist service.
     */
    MediaItem.prototype.destroy = function () {
        this.unsubscribe();
        this.item = null;
        this.pagination = null;
        this.paginatedData = [];
        this.subscriptions = [];
        this.player.handleContinuousPlayback = true;
    };
    /**
     * Load more tracks for playlist.
     */
    MediaItem.prototype.loadMore = function () {
        var _this = this;
        this.loading = true;
        var page = this.pagination.current_page + 1;
        return this.loadNextPage(this.item.id, page).toPromise().then(function (response) {
            _this.pagination = response;
            _this.paginatedData = _this.paginatedData.concat(response.data);
            _this.player.queue.append(_this.getTracks(response.data));
        }).then(function () {
            _this.loading = false;
        });
    };
    /**
     * Check if more artists can be loaded for current genre.
     */
    MediaItem.prototype.canLoadMore = function () {
        return !this.loading && this.pagination.current_page < this.pagination.last_page;
    };
    /**
     * Lazy load more tracks from user library for
     * continuous playback after current track ends.
     */
    MediaItem.prototype.bindToPlaybackEndEvent = function () {
        var _this = this;
        var sub = this.player.state.onChange$.subscribe(function (type) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (type !== 'PLAYBACK_ENDED')
                            return [2 /*return*/];
                        //if player is not playing this artist currently, bail
                        if (this.player.queue.getQueuedItem() !== this.item.id)
                            return [2 /*return*/];
                        if (!(this.player.getQueue().isLast() && this.canLoadMore())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadMore()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.playNext();
                        return [2 /*return*/];
                }
            });
        }); });
        this.subscriptions.push(sub);
    };
    /**
     * Unsubscribe from all subscriptions this service made.
     */
    MediaItem.prototype.unsubscribe = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    return MediaItem;
}());
export { MediaItem };
