import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { WebPlayerUrls } from "../../web-player-urls.service";
import { Player } from "../../player/player.service";
import { Artist } from "../../../models/Artist";
import { ArtistService } from "../artist.service";
import { WebPlayerImagesService } from "../../web-player-images.service";
var ArtistItemComponent = /** @class */ (function () {
    /**
     * ArtistItemComponent Constructor
     */
    function ArtistItemComponent(urls, artistService, player, wpImages) {
        this.urls = urls;
        this.artistService = artistService;
        this.player = player;
        this.wpImages = wpImages;
    }
    ArtistItemComponent.prototype.ngOnDestroy = function () {
        this.artistService.destroy();
    };
    /**
     * Check if artist is playing.
     */
    ArtistItemComponent.prototype.playing = function () {
        return this.artistService.playing(this.artist);
    };
    /**
     * Play all artist tracks.
     */
    ArtistItemComponent.prototype.play = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.player.stop();
                        this.player.state.buffering = true;
                        return [4 /*yield*/, this.artistService.init(this.artist.id, { top_tracks: true })];
                    case 1:
                        _a.sent();
                        this.artistService.play();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause the player.
     */
    ArtistItemComponent.prototype.pause = function () {
        this.player.pause();
    };
    return ArtistItemComponent;
}());
export { ArtistItemComponent };
